<template>
  <div v-if="currentOrder">
    <Loading v-if="loadingActive"/>
    <InfoDialog v-if="infoModalActive" :modal-message="modalMessage" v-on:close-modal="closeInfoModal"/>
    <confirm-dialog :modal-message="modalMessage" v-if="confirmActive" v-on:confirm="confirm" v-on:cancel="cancel"/>

    <div class="modal  fade" id="exampleModal" style="z-index: 10005" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" >
        <div class="modal-content bg-light " style="border-radius: var(--border-radius); border: none">
          <div class="modal-header d-flex justify-content-between align-items-center">
            <div class="d-flex">
              <button class="btn" style="background-color: var(--btnLight);" @click="printThis"><i class="fas fa-save"></i></button>
              <button class="btn ms-1" style="background-color: var(--btnLight);" @click="printVoucher"><i class="fas fa-print"></i></button>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="w-75 p-3 mx-auto bg-white rounded"  id="printcontent" style="font-size: 14px; font-weight: bold;" ref="printcontent">
              <div class="text-center">
                <h3>{{owner.name}}</h3>
                <p>{{owner.address}}</p>
                <p>Ph: {{owner.phone}}</p>
                <div class="d-flex flex-column text-start">
                  <span>Voucher ID:{{vouncherId}}</span>
                  <span>Date:{{this.currentDateTime}}</span>
                  <span v-if="showName === 'true'">Customer Name: {{customer.name}}</span>
                  <span v-if="showPhone === 'true'">Customer Phone: 09{{customer.phone}}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <span>Items</span>
                  <span>Price</span>
                </div>
                <hr>
                <div class="d-flex justify-content-between align-items-start">
                  <span class="w-50 text-start">{{currentOrder.itemName}} ({{currentOrder.sellPrice}} x {{currentOrder.count}})</span>
                  <span class="w-50 text-end">{{currentOrder.totalPrice}} Ks</span>
                </div>
                <hr>
                <!--                  <div class="d-flex justify-content-between">
                                    <span>Total</span>
                                    <span>{{ currentOrder.totalPrice }}</span>
                                  </div>-->
                <h4>Thank You!</h4>
                <span>Power by Light Idea Software Development</span>


              </div>
            </div>
            <!--              <button class="btn btn-primary mx-auto w-50 mt-5">Print</button>-->
          </div>
          <div class="modal-footer">
            <!--                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>-->
            <button class="btn btn-primary mx-auto my-2 w-50" @click="sellNow" v-if="this.currentOrder.orderStatus==='confirmed'" data-bs-dismiss="modal">Sell Now</button>

          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid" style="background-color: var(--background-color); color: var(--text-color);">
      <div class="row ">
        <div class="col-lg-2 p-0">
          <AdminSidebar/>
        </div>
        <div class="col-12 col-lg-10 pt-5">
          <div class="row">
            <div class="col-12 col-lg-8">
              <div class="d-flex mt-2 rounded shadow-sm align-items-center">
                <button @click="$router.back()" class="btn btn--white">
                  <i class="fas fa-chevron-left"> </i>
                </button>
                <span class="fs-3">Live Order Details</span>
              </div>
              <div class="d-flex px-2 justify-content-between">
                Date: {{currentOrder.time.toDate().toLocaleDateString()}}
                <span><i class="far fa-clock"></i>{{currentOrder.time.toDate().toLocaleTimeString()}}</span>
              </div>
              <div class="rounded p-2 shadow-sm">
                <div v-if="currentOrder.orderStatus==='pending'" class="d-flex align-items-center">
                  <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                    <span class=" "><i class="fas fa-circle-check "></i></span>
                    <span>Pending</span>
                  </div>
                  <div class="loading-bar"  style="background-color: var(--primary);">
                  </div>
                  <div class="d-flex flex-column text-center status-box">
                    <span><i class="fas fa-spinner "></i></span>
                    <span>Confirmed</span>
                  </div>
                  <div class="loading-bar">
                  </div>
                  <div class="d-flex flex-column text-center status-box">
                    <span><i class="fas fa-spinner "></i></span>
                    <span>Paid</span>
                  </div>
                </div>
                <div v-if="currentOrder.orderStatus==='confirmed'" class="d-flex align-items-center">
                  <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                    <span class=" "><i class="fas fa-circle-check "></i></span>
                    <span>Pending</span>
                  </div>
                  <div class="loading-bar" style="background-color: var(--primary);">
                  </div>
                  <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                    <span><i class="fas fa-circle-check "></i></span>
                    <span>Confirmed</span>
                  </div>
                  <div class="loading-bar" style="background-color: var(--primary);">
                  </div>
                  <div class="d-flex flex-column text-center status-box">
                    <span><i class="fas fa-spinner "></i></span>
                    <span>Paid</span>
                  </div>
                </div>
                <div v-if="currentOrder.orderStatus==='paid'" class="d-flex align-items-center">
                  <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                    <span><i class="fas fa-circle-check "></i></span>
                    <span>Pending</span>
                  </div>
                  <div class="loading-bar" style="background-color: var(--primary);">
                  </div>
                  <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);">
                    <span><i class="fas fa-circle-check "></i></span>
                    <span>Confirmed</span>
                  </div>
                  <div class="loading-bar" style="background-color: var(--primary);">
                  </div>
                  <div class="d-flex flex-column text-center status-box" style="background-color: var(--primary); color: var(--light);" >
                    <span><i class="fas fa-circle-check "></i></span>
                    <span>Paid</span>
                  </div>
                </div>
                <div v-if="currentOrder.orderStatus==='canceled'">
                  <span class="badge rounded-pill bg-danger">{{currentOrder.orderStatus}}</span>
                </div>
              </div>

              <div class="card">
                <div class="card-body"  style="height: 200px; overflow-x: scroll; overflow-y: scroll;">
                  <div class="d-flex justify-content-center align-items-center">
                    <table class="table">
                      <thead>
                      <tr>
                        <th>Photo</th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Count</th>
                        <th>Total</th>
                      </tr>
                      </thead>

                      <tbody>

                      <tr>
                        <td>
                          <img :src="currentOrder.image" class="rounded shadow-sm" style="width:80px;height: 80px;" alt="">
                        </td>
                        <td>
                          <span>{{currentOrder.itemName}}</span>
                        </td>
                        <td>
                          <span>{{currentOrder.sellPrice}}</span>
                        </td>
                        <td>
                          <span>{{currentOrder.count}}</span>
                        </td>

                        <td>
                          <span>{{currentOrder.totalPrice}}</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>


                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between p-2">
                <div class="d-flex">
                  <!--<button class="btn btn-sm mx-1 btn-primary" @click="askConfirm">Send Confirmation</button>-->
                  <button class="btn btn-sm mx-1 btn-success" @click="confirmOrder" v-if="currentOrder.orderStatus!=='confirmed' && currentOrder.orderStatus!=='paid'">Confirm</button>
                  <button class="btn btn-sm mx-1 btn-danger" @click="cancelOrder" v-if="currentOrder.orderStatus!=='paid'">Cancel</button>
                </div>
                <button type="button" v-if="currentOrder.orderStatus==='confirmed' " class="btn btn-sm mx-1 btn-dark mx-3" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Generate Invoice
                </button>
              </div>

<!--              Customer   :{{customer.id}}<br>
              Order User:{{currentOrder.orderUserId}}<br>
              {{currentOrder.count}}<br>
              {{originalProduct.stock}} <br>
              {{owner.shopId}}<br>
              {{cashier[0].casherCode}}-->

            </div>

            <div class="col-12 col-lg-4">
              <div class="card">
                <span class="fs-3">Customer Details</span>

                <div class="card-body table-responsive">
                  <table class="table">
                    <tbody>
                    <tr>
                      <td>Name</td>
                      <td>:{{customer.name}}</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td><a :href="'tel:+959'+customer.phone">09{{customer.phone}}</a></td>
                    </tr>
<!--                    <tr>-->
<!--                      <td>Phone2</td>-->
<!--                      <td><a :href="'tel:+959'+customer.otherPhone">09{{customer.otherPhone}}</a></td>-->
<!--                    </tr>-->
                    <tr>
                      <td>Name</td>
                      <td class="text-wrap">:{{customer.email}}</td>
                    </tr>
                    <tr>
                      <td>Address</td>
                      <td>:{{customer.address}}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h3>Transaction Record</h3>
                  <p>Payment Type: {{currentOrder.paymentType}}</p>

                  <img :src="currentOrder.transactionImg" v-if="currentOrder.transactionImg!=='default'" class="shadow-sm" width="100" height="150" alt="">
                </div>
              </div>
              <div class="card">
                <div class="card-body d-flex flex-column">
                  <div class="d-flex flex-column" v-if="selectedDeliveryService">
                    <span style="color: var(--primary);">Delivery Information</span>
                    <span>Name: {{selectedDeliveryService.name}}</span>
                    <span>Phone: {{selectedDeliveryService.phone}}</span>
                    <span>Available Cities: <span class="badge bg-secondary me-1 d-inline" v-for="(city,index) in selectedDeliveryService.availableCities" :key="index">{{city}}</span></span>
                    <span>Inside City: {{selectedDeliveryService.feeRange}} MMK</span>
                    <span>Outside City: {{selectedDeliveryService.externalFeeRange}} MMK</span>
                  </div>

                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <div class="d-flex flex-column">
                    <span style="color: var(--primary);">User Choice</span>
                    <span>Name: {{currentOrder.deliveryServiceName}}</span>
                    <span>City To Deliver: {{currentOrder.cityToDeliver}}</span>
                    <span>Cost: {{currentOrder.deliveryFeeRange}} MMK</span>
                  </div>
                </div>
              </div>
              <div class="card p-2">
                <span class="fs-3">History</span>
                <div class="p-2 mt-2" style="background-color: var(--light); border-radius: var(--border-radius);" v-for="(record,index) in recordHistory" :key="index">
                  <div class="d-flex justify-content-between">
                    <div>
                      <span class="badge rounded-pill bg-warning" style="min-width: 100px;font-size: 12px;" v-if="record.orderStatus === 'pending'">Order Date</span>
                      <span class="badge rounded-pill bg-primary" style="min-width: 100px;font-size: 12px;" v-else-if="record.orderStatus === 'confirmed'">Confirmed Date</span>
                      <span class="badge rounded-pill bg-success" style="min-width: 100px;font-size: 12px;" v-else-if="record.orderStatus === 'paid'">Paid Date</span>
                      <span class="badge rounded-pill bg-danger" style="min-width: 100px;font-size: 12px;" v-else>Cancelled Date</span>

                    </div>
                    <span>{{record.statusDateTime}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import db, {timestamp} from '../../config/FirebaseInit';
import InfoDialog from "@/components/InfoDialog";
import ConfirmDialog from "@/components/ConfirmDialog";
import AdminSidebar from "@/components/AdminSidebar";
// import sellProduct from "@/views/shop-owner/SellProduct";
import Loading from "@/components/Loading";
import html2canvas from "html2canvas";
export default {
  components: {Loading, ConfirmDialog, InfoDialog, AdminSidebar},
  props:['order_id'],
  data(){
    return{
      sellProductDB:'',
      currentOrder:'',
      selectedDeliveryService:'',

      customer:'',
      loadingActive:false,
      infoModalActive:false,
      confirmActive:false,
      modalMessage:'',
      sellItems:[],
      cashier:[],
      originalProduct:'',
      vouncherId:'',
      casherCode:'',
      recordHistory:[],

      confirmSellProduct:{
        countList:[],
        nameList:[],
        sellPriceList:[],
        buyPriceList:[],
        productIdList:[],
        itemCodeList:[],
        sellType:'liveSell',
        casherCode:'',
        id:'',
        time:'',
        day:'',
        month:'',
        dateTime:'',
        year:'',
        discount:'',
        customerId:'',
        customerName:'',
        customerPhone:'',
        promoCode:'',
        tax:'0',
        totalPrice:'',
        vouncherId:'',
    }
    }
  },
  computed:{
    showName(){
      return localStorage.getItem('showCustomerName');
    },
    showPhone(){
      return localStorage.getItem('showCustomerPhone');
    },
    owner(){
      return this.$store.getters.getShopOwner;
    },
    today(){
      return this.getToday();
    },
    currentDateTime(){
      return this.$store.getters.getDateTime;
    },
    currentDay(){
      return this.$store.getters.getDay;
    },
    currentMonth(){
      return this.$store.getters.getMonth;
    },
    currentYear(){
      return this.$store.getters.getYear;
    },
    cashierAcc(){
      return this.$store.getters.getCashierAcc;
    }

  },

  async mounted() {
    this.$store.dispatch("getDateTime");
    this.$store.dispatch("getDay");
    this.$store.dispatch("getMonth");
    this.$store.dispatch("getYear");
    /*if(localStorage.getItem('defaultCashier')){
      this.casherCode = localStorage.getItem('defaultCashier');
    }else {
      this.casherCode = 'C1';
    }*/
    this.casherCode = this.cashierAcc.accountCode;
    await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').doc(this.order_id).collection('records').orderBy('time','desc').onSnapshot(snapshot=>{
      this.recordHistory = [];
      console.log(snapshot.size)
      snapshot.forEach(doc=>{
        this.recordHistory.push(doc.data());
      })
      console.log('Record',this.recordHistory);
    });

    await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').doc(this.order_id).onSnapshot(snapshot => {
      this.currentOrder = snapshot.data();

     // console.log('current Order',this.currentOrder.productId);
      db.collection(this.owner.city).doc(this.owner.shopId).collection('products').doc(this.currentOrder.productId).onSnapshot(snapshot2 => {
        this.originalProduct=snapshot2.data();
       // console.log('original product',snapshot2.data())
      });
      db.collection('users').doc(this.currentOrder.orderUserId).onSnapshot(snapshot1=>{
        this.customer = snapshot1.data();
      });



      if(this.originalProduct.deliveryServiceId!==''){
        db.collection('deliveryServices').doc(this.originalProduct.deliveryServiceId).onSnapshot(snapshot=>{
          this.selectedDeliveryService = snapshot.data();
        });
      }
    });


    await db.collection(this.owner.city).doc(this.owner.shopId).collection('sellProducts').where('day','==',this.today).where('casherCode','==',this.casherCode).orderBy('time','desc').get().then(snapshot => {
      this.sellItems = [];
      snapshot.forEach(doc=>{
        this.sellItems.push(doc.data());
      });
      this.vouncherId = this.generateVoucherId();
    })

  },
  methods:{

    getToday(){
      const date = new Date();
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      return day+"-"+month+"-"+year;
    },
    confirm(){
      this.confirmActive = !this.confirmActive;
    },
    cancel(){
      this.confirmActive = !this.confirmActive;
    },
    async confirmOrder(){
      this.loadingActive = true;
      //console.log('accepted list',this.currentOrder.acceptedList)
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').doc(this.order_id).update({
        'orderStatus':'confirmed',
        'statusDay':this.currentDay,
        'statusDateTime':this.currentDateTime,
      }).then(()=>{
        console.log('order confirmed');
        let recordRef = db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').doc(this.order_id).collection('records').doc();
        recordRef.set({
          'statusDay':this.currentDay,
          'statusDateTime':this.currentDateTime,
          'orderStatus':'confirmed',
          'id':recordRef.id,
          'time':timestamp,
        }).then(()=>{
          console.log('confirmed recorded');
          db.collection('users').doc(this.customer.id).collection('liveOrders').doc(this.order_id).update({
            'orderStatus':'confirmed',
            'statusDay':this.currentDay,
            'statusDateTime':this.currentDateTime,
          }).then(()=>{
            console.log('user order confirmed');
            let userRecordRef = db.collection('users').doc(this.customer.id).collection('liveOrders').doc(this.order_id).collection('records').doc();
            userRecordRef.set({
              'statusDay':this.currentDay,
              'statusDateTime':this.currentDateTime,
              'orderStatus':'confirmed',
              'id':userRecordRef.id,
              'time':timestamp,

            }).then(()=>{
              console.log('user order confirmed recorded')
              this.loadingActive = false;
            });
          });
        });
      })
    },

    async cancelOrder(){
      this.loadingActive = true;
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').doc(this.order_id).update({
        'orderStatus':'cancelled',
        'statusDay':this.currentDay,
        'statusDateTime':this.currentDateTime,
      }).then(()=>{
        console.log('order cancelled');
        let recordRef = db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').doc(this.order_id).collection('records').doc();
        recordRef.set({
          'statusDay':this.currentDay,
          'statusDateTime':this.currentDateTime,
          'orderStatus':'cancelled',
          'id':recordRef.id,
          'time':timestamp,

        }).then(()=>{
          console.log('cancelled recorded');
          db.collection('users').doc(this.customer.id).collection('liveOrders').doc(this.order_id).update({
            'orderStatus':'cancelled',
            'statusDay':this.currentDay,
            'statusDateTime':this.currentDateTime,
          }).then(()=>{
            console.log('user order cancelled');
            let userRecordRef = db.collection('users').doc(this.customer.id).collection('liveOrders').doc(this.order_id).collection('records').doc();
            userRecordRef.set({
              'statusDay':this.currentDay,
              'statusDateTime':this.currentDateTime,
              'orderStatus':'cancelled',
              'id':userRecordRef.id,
              'time':timestamp,

            }).then(()=>{
              console.log('user order cancelled recorded')
              this.loadingActive = false;
            });
          });
        });
      })
    },
    closeInfoModal(){
      this.infoModalActive = !this.infoModalActive;
    },
    generateVoucherId(){
      let padNumber = this.sellItems.length+1;
      const date = new Date();
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      let twoDigitMonth = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
      let twoDigitYear = date.getFullYear().toString().slice(-2);
      let vId='';
      vId = this.owner.shopCode+localStorage.getItem('defaultCashier')+twoDigitYear+twoDigitMonth+day+padNumber.toString().padStart(5,'0');
     // console.log(vId)
      return vId;
    },
    async sellNow(){
      this.loadingActive = true;
      this.sellProductDB = await db.collection(this.owner.city).doc(this.owner.shopId).collection('sellProducts').doc(this.order_id);
      this.loadingActive=true;
      this.confirmSellProduct.casherCode = this.casherCode;
      this.confirmSellProduct.promoCode = this.currentOrder.promoCode;
      this.confirmSellProduct.month=this.currentMonth;
      this.confirmSellProduct.day = this.currentDay;
      this.confirmSellProduct.year = this.currentYear;
      this.confirmSellProduct.time = timestamp;
      this.confirmSellProduct.dateTime = this.currentDateTime;
      this.confirmSellProduct.buyPriceList.push(this.currentOrder.buyPrice);
      this.confirmSellProduct.sellPriceList.push(this.currentOrder.sellPrice);
      this.confirmSellProduct.nameList.push(this.currentOrder.itemName);
      this.confirmSellProduct.countList.push(this.currentOrder.count);
      this.confirmSellProduct.productIdList.push(this.currentOrder.productId);
      this.confirmSellProduct.itemCodeList.push(this.currentOrder.itemCode);
      this.confirmSellProduct.discount= this.currentOrder.discount;
      this.confirmSellProduct.id = this.sellProductDB.id;
      this.confirmSellProduct.customerId=this.customer.id;
      this.confirmSellProduct.customerName=this.customer.name;
      this.confirmSellProduct.customerPhone='09'+this.customer.phone;
      this.confirmSellProduct.totalPrice=this.currentOrder.totalPrice;
      this.confirmSellProduct.vouncherId = this.vouncherId;
     // const recordRef = await db.collection(this.owner.city).doc(this.owner.shopId).collection('products').doc(this.currentOrder.productId).collection("records");
     // console.log(recordRef.id)
      let newStock = 0;

      if(this.confirmSellProduct.countList[0] <= this.originalProduct.stock){
        newStock = parseInt(this.originalProduct.stock) - parseInt(this.currentOrder.count);
      }
     // console.log('new Stock', newStock)
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('products').doc(this.currentOrder.productId).update({stock: newStock.toString()}).then(()=>{
        //console.log('stock updated');
       // console.log('stock updated');
        const recordRef = db.collection(this.owner.city).doc(this.owner.shopId).collection('products').doc(this.currentOrder.productId).collection("records").doc();
        recordRef.set({
          'id':this.originalProduct.id,
          'recordId':recordRef.id,
          'itemCode':this.originalProduct.itemCode,
          'barcode':this.originalProduct.barcode,
          'itemName':this.originalProduct.itemName,
          'buyPrice':this.originalProduct.buyPrice,
          'sellPrice':this.currentOrder.sellPrice,
          'stock':this.originalProduct.stock,
          'description':this.originalProduct.description,
          'type':this.originalProduct.type,
          'color':this.originalProduct.color,
          'size':this.originalProduct.size,
          'weight':this.originalProduct.weight,
          'time':timestamp,
          'day':this.today,
          'month':this.currentMonth,
          'year':this.currentYear,
          'dateTime':this.currentDateTime,
          'rating':this.originalProduct.rating,
          'discount':this.currentOrder.discount,
          'note':'default',
          'process': 'liveSell',
          'count':"-"+this.currentOrder.count
        }).then(()=>{
         // console.log('record updated')
          this.sellProductDB.set(this.confirmSellProduct).then(()=>{
           // console.log('sell product ok')
            db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').doc(this.order_id).update({
              'orderStatus':'paid',
              'statusDay':this.currentDay,
              'statusDateTime':this.currentDateTime,
            }).then(()=>{
              console.log('owner order paid');
              let recordRef = db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').doc(this.order_id).collection('records').doc();
              recordRef.set({
                'statusDay':this.currentDay,
                'statusDateTime':this.currentDateTime,
                'orderStatus':'paid',
                'id':recordRef.id,
                'time':timestamp,
              }).then(()=>{
                console.log('owner order paid record')
                db.collection('users').doc(this.currentOrder.orderUserId).collection('liveOrders').doc(this.order_id).update({
                  'orderStatus':'paid',
                  'statusDay':this.currentDay,
                  'statusDateTime':this.currentDateTime,
                }).then(()=>{
                  console.log('user order paid');
                  let userRecordRef = db.collection('users').doc(this.customer.id).collection('liveOrders').doc(this.order_id).collection('records').doc();
                  userRecordRef.set({
                    'statusDay': this.currentDay,
                    'statusDateTime': this.currentDateTime,
                    'orderStatus': 'paid',
                    'id': userRecordRef.id,
                    'time': timestamp,
                  }).then(()=> {
                    console.log('user order paid record')
                    this.loadingActive=false;
                    this.modalMessage='Successfully sold!';
                    this.infoModalActive = true;
                  });
                });
              });
            });
          });
        });
      });

    },

    async printThis() {
     // console.log("printing..",this.vouncherId);
      const el = this.$refs.printcontent;

      const options = {
        type: "dataURL",
      };
      const printCanvas = await html2canvas(el, options);

      const link = document.createElement("a");
      link.setAttribute('download', `${this.vouncherId}.png`);
      link.setAttribute(
          "href",
          printCanvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream")
      );
      link.click();

     // console.log("done");
    },
    printVoucher() {
      // Get HTML to print from element
      const prtHtml = document.getElementById('printcontent').innerHTML;

      // Get all stylesheets HTML
      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }
      const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
        </head>
        <body>
          ${prtHtml}
        </body>
      </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();

    },

  }
}
</script>

<style scoped>
.card .card-body{
  padding: 3px;
  background-color: var(--side-bar-bg) !important;
}
.card ul{
  text-align: start;
  width: inherit;
  padding: 0;
  list-style-type: none;
  background-color: var(--light);
  margin: 2px;
  border-radius: var(--border-radius);

}
tr{
  color: var(--text-color);
}
.loading-bar{
  width: 30px; height: 3px; background-color: var(--softPrimary);
}
.status-box{
  font-size: 10px;
  background-color: var(--light);
  padding: 2px;
  border-radius: var(--border-radius);
  min-width: 45px;
}
.card li{
  height: 60px;
  min-width: 80px;
  text-align: start;
  margin: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:10px 5px;
  border-bottom: 2px solid var(--white);
}

</style>